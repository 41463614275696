import { inject } from '@angular/core';

import { ISAssignedCategory } from '@ess/integrated-search/shared/utils';
import { SharedFeatureServicesUserInteractionService } from '@ess/shared/feature/services';
import { EssCountryOptionsHelper, SharedGridColumnsHelper } from '@ess/shared/utils/helpers';
import {
  SharedColDef,
  SharedColumnState,
  SharedExpandableCellRendererParams,
  SharedGridCellRendererParams,
  SharedGridColumnsService,
  SharedGridDragStoppedEvent,
  SharedGridNewColumnsLoadedEvent,
  SharedGridOptions,
  SharedGridValueGetterParams,
  SharedUserInteractionService,
  SharedValueWithChipsCellRendererParams,
} from '@ess/shared/utils/models';

type SearchTermBase = { search_term: string; categories: ISAssignedCategory[] };
type UrlBase = { url: string; categories: ISAssignedCategory[] };

export abstract class AbstractIntegratedSearchSharedFeatureGridColumnsService extends SharedGridColumnsService {
  protected readonly _uiService: SharedUserInteractionService = inject(SharedFeatureServicesUserInteractionService);

  protected get _searchTermColumn(): SharedColDef {
    return {
      headerName: 'Search term',
      field: 'search_term',
      sortable: true,
      pinned: 'left',
      lockPosition: 'left',
      minWidth: 240,
      cellRenderer: 'searchTermRenderer',
      autoHeight: true,
      toggleEnabled: false,
      initialSort: 'asc',
      cellRendererParams: this._searchTermCellParams(true),
    };
  }

  protected get _urlColumn(): SharedColDef {
    return {
      headerName: 'Landing page',
      field: 'url',
      sortable: true,
      pinned: 'left',
      minWidth: 240,
      cellRenderer: 'urlRenderer',
      autoHeight: true,
      toggleEnabled: false,
      initialSort: 'asc',
      valueGetter: this.__urlValueGetter(),
      cellRendererParams: this.__urlCellParams(),
      suppressMovable: true,
      lockPosition: 'left',
    };
  }

  onColumnStateChanged(path: string): Pick<SharedGridOptions, 'onNewColumnsLoaded' | 'onDragStopped'> {
    return {
      onDragStopped: ({ api }: SharedGridDragStoppedEvent) => this.__saveColumnState(api.getColumnState(), path),
      onNewColumnsLoaded: ({ api }: SharedGridNewColumnsLoadedEvent) =>
        this.__saveColumnState(api.getColumnState(), path),
    };
  }

  protected _orderAndMergeColumnDefinitions(allColumns: SharedColDef[], path: string): SharedColDef[] {
    const columnState = this.__loadColumnState(path);

    if (!columnState?.length) {
      return allColumns;
    }

    const mergedDefinitions = SharedGridColumnsHelper.mergeColumnsDefinitions(allColumns, columnState);

    return SharedGridColumnsHelper.sortColumns(mergedDefinitions, columnState);
  }

  protected _countryCellParams(): ({
    data,
  }: SharedGridCellRendererParams<SearchTermBase>) => SharedExpandableCellRendererParams {
    return ({ value }: SharedGridCellRendererParams) =>
      ({
        tooltips: value?.map(
          (alpha3: string) => EssCountryOptionsHelper.getCountryNameByAlpha3(alpha3) || 'Unknown country',
        ),
        displayChips: true,
      }) as SharedExpandableCellRendererParams;
  }

  protected _isMaster(data?: SearchTermBase | null): boolean {
    return !!data && 'isMaster' in data;
  }

  protected _searchTermCellParams(
    withMaster = false,
  ): ({ data }: SharedGridCellRendererParams<SearchTermBase>) => SharedValueWithChipsCellRendererParams<string | null> {
    return ({ data }: SharedGridCellRendererParams<SearchTermBase>) =>
      ({
        ...(withMaster && { isMaster: this._isMaster(data) }),
        ...this.__getCategoriesChips(data?.categories),
      }) as SharedValueWithChipsCellRendererParams<string | null>;
  }

  private __urlCellParams(): ({
    data,
  }: SharedGridCellRendererParams<UrlBase>) => SharedValueWithChipsCellRendererParams<string | null> {
    return ({ data }: SharedGridCellRendererParams<UrlBase>) =>
      ({
        ...this.__getCategoriesChips(data?.categories),
        actions: [
          {
            icon: 'open_in_new',
            label: 'Open link in new tab',
            action: () => this._uiService.openLink(data?.url),
          },
          {
            icon: 'content_copy',
            label: 'Copy link to clipboard',
            action: () => this._uiService.copyText(data?.url),
          },
        ],
      }) as SharedValueWithChipsCellRendererParams<string | null>;
  }

  private __urlValueGetter(): ({ data }: SharedGridValueGetterParams<UrlBase, string>) => string {
    return ({ data }: SharedGridValueGetterParams<UrlBase, string>) =>
      data ? data.url.replace(/^(https?:\/\/)?(www\.)?/, '') : '';
  }

  private __getCategoriesChips(
    categories?: ISAssignedCategory[],
  ): Partial<SharedValueWithChipsCellRendererParams<string | null>> {
    return {
      chips: categories?.map((category: ISAssignedCategory) => category.values[0].value),
      tooltips: categories?.map((category: ISAssignedCategory) => `${category.title}: ${category.values[0].value}`),
    };
  }

  private __saveColumnState(columnState: SharedColumnState[], path: string): void {
    if (columnState?.length) {
      localStorage.setItem(path, JSON.stringify(columnState));
    }
  }

  private __loadColumnState(path: string): SharedColumnState[] | null {
    return JSON.parse(localStorage.getItem(path) || 'null');
  }
}
